.pageTitle {
  font-size: 40px;
  line-height: 60px;
  color: var(--neutral-dark-blue);
  margin-bottom: 12px;
}

@media (max-width: 1199px) {
  .pageTitle {
    font-size: 32px;
    line-height: 43px;
  }
}

@media (max-width: 991px) {
  .pageTitle {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 16px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .pageTitle {
    text-align: left;
  }
}
