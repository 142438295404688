body .progressBarContainer {
  position: relative;
  padding: 18px 0;
}

.progressBar:global(.progress) {
  max-width: unset;
}

.progressBar :global(.progress-bar) {
  background-color: var(--functional-warning-bold);
}

body .isExamPassed :global(.progress-bar) {
  background-color: var(--functional-success);
}

.progressBarContainer :global(.progress-bar:after) {
  content: '';
  position: absolute;
  right: 10%;
  height: 25px;
  width: 5px;
  background-color: var(--theme-primary);
}
