.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.title {
  line-height: 45px;
}
.upTitle {
  margin-bottom: 10px;
}
.imageContainer {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  margin-top: 20px;
  padding-bottom: 20px;
}
.imagesWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 70%;
  padding-bottom: 10px;
  padding-top: 10px;
}
.imagesWrapper img {
  flex: 1 1 30%;
  max-width: 30%;
  padding: 5px;
  box-sizing: border-box;
}
.button {
  padding: 10px 30px;
  background-color: var(--blackColor);
  color: var(--white);
  border-radius: 10px;
  font-size: 22px;
}
.button:hover,
.button:active {
  color: var(--white);
}
@media (max-width: 500px) {
  .container h5 {
    text-align: center;
    font-size: 20px;
  }
  .imagesWrapper {
    width: 100%;
  }
  .imagesWrapper img {
    flex: 1 1 30%;
    max-width: 30%;
    padding: 5px;
    box-sizing: border-box;
  }
}
