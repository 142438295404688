.questionContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  padding: 24px 24px 32px;
  margin-bottom: 30px;
  border-radius: 6px;
  align-items: stretch;
  border: 1px solid transparent;
}

.videoImage span {
  display: none;
}

.videoImage img {
  display: block !important;
}

.questionContent {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100%;
  max-width: 33.3%;
  width: 100%;
  margin-left: 60px;
  align-self: stretch;
}

.questionList {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.questionTitle {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-dark-blue-70);
  min-height: 72px;
}

.questionPaginator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.btnPrev,
.btnNext,
.btnExit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}

.btnExit {
  background-color: #28a745 !important;
}

.btnCenter {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02px;
  color: var(--whiteColor);
  flex-grow: 1;
  border-radius: 4px;
  padding: 0;
  height: 48px;
  opacity: 1 !important;
}

.btnCorrectAnswer {
  border-color: #28a745 !important;
  background-color: #28a745 !important;
}

.btnWrongAnswer {
  border-color: #e20000 !important;
  background-color: #e20000 !important;
}

.btnNext,
.btnExit {
  margin-left: 10px;
}

.btnPrev {
  margin-right: 10px;
}

.btnPrev > i {
  transform: rotate(180deg);
}

.btnPrev > i,
.btnNext > i {
  font-size: 20px;
}

.questionListItem {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  margin-bottom: 12px;
}

.trueAnswer {
  border: 1px solid var(--functional-success);
}

.falseAnswer {
  border: 1px solid var(--functional-warning);
}

@media (max-width: 991px) {
  .questionContainer {
    flex-flow: row wrap;
  }

  .questionContent {
    max-width: none;
    width: 100%;
    margin-left: 0;
    padding: 0 10px;
  }

  .questionPaginator {
    max-width: 50%;
    width: 100%;
    margin-top: 18px;
  }

  .questionList {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .questionPaginator {
    max-width: 100%;
  }

  .questionContainer {
    padding: 0 0 20px;
  }

  .originLink {
    display: none;
  }

  .originLinkMobile {
    display: inline-block;
  }
}
