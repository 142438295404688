.cardCategoriesMobile {
  display: none;
}

.cardCategoriesList .cardCategoriesItem :global .card {
  border-radius: 6px;
  background-color: var(--whiteColor);
}

.cardCategoriesItem:not(:last-child) {
  margin-bottom: 12px;
  position: relative;
}

.cardBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: var(--badge-category-color);
  top: -4px;
  right: -11px;
  z-index: 1;
}

.badgeText {
  font-weight: 500;
  font-size: 12px;
}

.cardCategoriesItem :global .card-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: relative;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0.1px;
  color: var(--neutral-dark-blue);
  border-bottom: none;
  background-color: var(--lavender-grey);
}

.cardCategoriesItem :global .card {
  width: 100%;
  border-color: transparent;
  transition: all ease 0.3s;
}

.cardCategoriesItem :global .card-body {
  padding: 18px 24px 21px;
  border: 1px solid transparent;
  width: 100%;
}

.cardCategoriesItem :global .collapse.show .card-body {
  width: 100%;
  border-color: var(--additional-blue);
  border-top: 0;
  border-radius: 0 0 10px 10px;
}

.cardCategoriesItem :global .card-header .icon-arrow {
  font-size: 24px;
  color: var(--theme-primary);
  transform: rotate(90deg);
}

.cardCategoriesItem :global .card-text {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .cardCategoriesMobile {
    display: block;
    padding: 10px 0;
  }
}
