.zoomBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: var(--whiteColor);
  border-radius: 6px;
  cursor: pointer;
  color: var(--theme-primary);
  transition: all ease 0.3s;
  border: 2px solid transparent;
}

.zoomBtn i {
  font-size: 26px;
}

.zoomBtn:hover {
  border: 2px solid var(--theme-primary);
}

@media (max-width: 991px) {
  .zoomBtn {
    width: 32px;
    height: 32px;
    right: 10px;
    bottom: 10px;
  }

  .zoomBtn i {
    font-size: 20px;
  }
}
