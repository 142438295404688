.jumbotron {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 290px;
  padding: 28px;
  background-color: var(--lavender-grey);
  border-radius: 10px;
}

.jumbotronHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.jumbotronHeaderItem {
  max-width: 60%;
}

.panelTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: var(--neutral-dark-blue);
}

.panelCount {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 52px;
  line-height: 100px;
  color: var(--neutral-gray-blue);
}

.panelDate,
.panelTime {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
}

.panelDate {
  color: var(--neutral-dark-blue);
}

.panelTime {
  color: var(--neutral-dark-blue-50);
}

.daysContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin-bottom: 14px;
}

.daysValue {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 92px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.daysLabel {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-left: 10px;
}

.mobileJumbotron {
  display: none;
}

@media (max-width: 991px) {
  .jumbotronHeader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .panelDateList {
    display: flex;
  }

  .panelDateList div {
    margin: 0 5px;
  }

  .jumbotron {
    padding: 10px 28px;
  }

  .pointsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .panelCount {
    width: 50%;
    padding: 0 20px;
  }

  .pointsContainer .panelCount {
    line-height: normal;
    font-size: 35px;
  }

  .panelTitle {
    color: var(--theme-primary);
    font-size: 18px;
    line-height: 23px;
    text-align: left;
    width: 100%;
  }

  .panelDate,
  .panelTime {
    font-family: 'Hind', sans-serif;
    font-size: 17px;
    font-weight: 500;
  }
}
