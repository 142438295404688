.cockpitBottomPanel {
  padding: 80px 0 100px;
}

.bestResultCardContainer {
  order: 1;
  margin: 10px auto 20px auto;
  max-width: 400px;
}

.bottomPanelList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.bottomPanelList .jumbotron {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 33%;
  margin-right: 0;
  border-radius: 10px;
}

.bottomPanelList .jumbotron:first-child .jumbotronBox .jumbotronBoxItem {
  padding: 16px;
}

.jumbotron {
  padding: 0;
  background-color: var(--lavender-grey);
  flex-grow: 1;
}

.jumbotron:not(:last-child) {
  margin-right: 20px;
}

.jumbotronBox {
  padding: 20px 28px;
}

.jumbotronBox:not(:last-child),
.jumbotronHeader {
  border-bottom: 1px solid var(--additional-gray-blue);
}

.jumbotronBox :global .h3 {
  color: var(--neutral-dark-blue);
  text-align: center;
}

.jumbotronBoxItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.jumbotronBoxItem:not(:last-child) {
  margin-bottom: 16px;
}

.instructorName {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  color: var(--neutral-dark-blue);
}

.instructorSc {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue);
}

.jumbotronTime,
.jumbotronDate,
.jumbotronCount {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.jumbotronDate {
  color: var(--neutral-dark-blue);
}

.jumbotronTime {
  color: var(--neutral-dark-blue-50);
}

.jumbotronCount {
  color: var(--functional-success);
}

.jumbotronText {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
}

.jumbotronIcon :global .icon-arrow {
  font-size: 14px;
  color: var(--theme-primary);
}

.progressBarContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.progressBarContent :global .progress {
  max-width: none;
  height: 16px;
}

:global .progress {
  flex-grow: 1;
  border-radius: 8px;
}

.progressBar :global .progress-bar {
  background-color: var(--theme-primary);
  border-radius: 8px;
  max-width: none;
}

.jumbotronBoxDate {
  width: 100%;
}

.instructorContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.daysValue {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 96px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-bottom: 14px;
}

.daysText {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
}

.dateItemText {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.dateItemLabel {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-50);
}

.jumbotronBoxBtn a,
.jumbotronBoxBtn .btn {
  width: 100%;
  border-radius: 0 0 6px 6px;
}

.jumbotronLabel {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-dark-blue-50);
  margin-bottom: 0;
}

.jumbotronValue {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--neutral-gray-blue);
  margin-bottom: 0;
}

.jumbotronBoxItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.dateItem {
  max-width: calc(50% - 10px);
  width: 100%;
}

.jumbotronPlaceholder {
  text-align: center;
}

.jumbotronImg {
  display: block;
  max-width: 100%;
  margin: 35px auto 44px;
}

.jumbotronHeader {
  min-height: 0;
  flex-shrink: 0;
}

.jumbotronFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.jumbotronFooter .jumbotronBox {
  border-bottom: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.jumbotronFooter .jumbotronBtn {
  min-height: 0;
  flex-shrink: 0;
}

.jumbotronIconBox {
  width: 100%;
  text-align: center;
}

.jumbotronIconBox i {
  font-size: 78px;
  color: var(--neutral-gray);
}

@media (max-width: 991px) {
  .cockpitBottomPanel {
    padding: 0;
  }

  .bottomPanelList {
    flex-flow: row wrap;
  }

  .bottomPanelList .jumbotron {
    max-width: 400px;
    width: 100%;
    margin: 0 auto 20px;
  }

  .bottomPanelList .jumbotron:first-child {
    margin-bottom: 0;
  }

  .bottomPanelList .jumbotron {
    background-color: var(--whiteColor);
  }

  .bottomPanelList .jumbotron:first-child .jumbotronBoxItem {
    background-color: var(--lavender-grey);
    border-radius: 6px;
  }

  .jumbotronHeader .jumbotronBox {
    padding: 20px 28px 0;
    border-bottom: none;
  }

  .jumbotronHeader {
    border-bottom: none;
  }

  .dateItemText {
    font-size: 17px;
  }

  .bottomPanelList .jumbotron:nth-child(2) {
    background-color: var(--neutral-light-gray);
    order: 3;
  }

  .bottomPanelList .jumbotron:nth-child(2) > .jumbotronBox:first-child {
    display: none;
  }

  .bottomPanelList .jumbotron:last-child {
    order: 2;
    flex-flow: row wrap;
  }

  .bottomPanelList .jumbotron:last-child > .jumbotronBox:first-child,
  .bottomPanelList .jumbotron:last-child > .jumbotronBox:nth-child(2) {
    max-width: 50%;
    width: 100%;
    border-bottom: none;
    background-color: var(--neutral-light-gray);
  }

  .bottomPanelList .jumbotron:last-child > .jumbotronBox:first-child {
    padding-right: 10px;
  }

  .jumbotronBox {
    padding: 20px;
  }

  .daysValue {
    display: inline-block;
    font-size: 48px;
    line-height: 31px;
    text-align: left;
    margin-bottom: 0;
  }

  .daysText {
    display: inline-block;
    font-size: 18px;
    line-height: 31px;
    text-align: left;
    margin-left: 5px;
  }

  .jumbotronBox :global .h3 {
    font-size: 18px;
    line-height: 23px;
    text-align: left;
  }

  .bottomPanelList .jumbotron:last-child .jumbotronBox {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .instructorContent {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .instructorContent :global(.schoolLogo) {
    max-width: unset;
    width: 100%;
  }

  .instructorInfo {
    margin-top: 20px;
    width: 100%;
  }
}
