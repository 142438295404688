.selectGameModeButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--text-color);
}

.selectGameModeButtonContainer button {
  margin: 5px 0;
}

.selectGameModeButtonContainer svg {
  width: 85%;
  height: 100%;
  padding-top: 9px;
  fill: var(--theme-primary);
}

.selected svg {
  fill: var(--whiteColor);
}

.selectGameModeButtonContainer + .selectGameModeButtonContainer {
  margin: 0 55px;
}

.selectGameModeButtonContainer:last-child {
  margin: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.button {
  border-radius: 100%;
  width: 62px;
  height: 62px;
  min-width: 62px !important;
  min-height: 62px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 0 5px 12px 5px;
  background-color: var(--button-icon-focused);
}

.button div {
  width: 100%;
}

/*.button div {*/
/*  width: 11px;*/
/*  border: 1px solid var(--theme-primary);*/
/*  margin: 0 2px;*/
/*}*/

.buttonIconSmallColumn {
  height: 11px;
}

.buttonIconSmallMiddleColumn {
  height: 23px;
}

.buttonIconSmallLargeColumn {
  height: 33px;
}

.buttonIconSmallLargeColumnCoverColor {
  background-color: var(--theme-primary);
}

/*is selected*/

/*.selected.button div {*/
/*  border: 1px solid var(--whiteColor);*/
/*}*/

/*.selected.button .buttonIconSmallLargeColumnCoverColor {*/
/*  background-color: white;*/
/*}*/

@media (max-width: 991px) {
  .selectGameModeButtonContainer + .selectGameModeButtonContainer {
    margin: 0 33px;
  }

  .selectGameModeButtonContainer:last-child {
    margin: 0;
  }
}

@media (max-width: 500px) {
  .selectGameModeButtonContainer,
  .selectGameModeButtonContainer + .selectGameModeButtonContainer {
    margin: 15px 0;
  }

  .selectGameModeButtonContainer button {
    margin: 0;
  }

  .selectGameModeButtonContainer:last-child {
    margin: 0;
  }
}
