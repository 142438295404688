.jumbotron {
  margin-bottom: 20px;
}

.contactPage {
  padding-top: 60px;
}

.contactPageTitle {
  margin-bottom: 32px;
}

.contactList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.contactItemFly {
  position: absolute;
  left: 100px;
  top: 50px;
  padding: 20px 24px;
  background-color: var(--neutral-light-gray-no-opacity);
  border-radius: 6px;
  max-width: 350px;
  width: 100%;
  z-index: 999;
  margin-bottom: 20px;
}

.contactTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.contactSubtitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.contactText {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}
.contactItemContent:not(:last-child) {
  margin-bottom: 10px;
}

.mapContainer {
  position: relative;
  margin-bottom: 60px;
}

.mapElementContainer {
  height: 520px;
}

@media (max-width: 991px) {
  .contactPage {
    padding-top: 24px;
  }

  .contactList {
    flex-flow: row wrap;
  }

  .contactItemFly {
    position: static;
    max-width: none;
  }

  .mapContainer {
    margin-bottom: 24px;
  }

  .mapElementContainer {
    height: 350px;
  }
}
