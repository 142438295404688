.schoolPage {
  padding: 60px 0 43px;
}

.schoolTopContainer {
  max-width: 520px;
  margin-bottom: 130px;
}

@media (max-width: 991px) {
  .schoolPage {
    padding: 24px 0 0;
  }

  .schoolTopContainer {
    margin-bottom: 40px;
  }
}
