.theoryModal :global .modal-dialog {
  max-width: 1200px;
  max-height: 750px;
  height: 100%;
  overflow: hidden;
}

.theoryModal :global .modal-body > div {
  height: 100%;
}

.theoryModal :global .modal-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--whiteColor);
}
