.vipCardMobile {
  display: none;
  position: relative;
}

.cardLogo {
  width: 100%;
}

@media (max-width: 767px) {
  .vipCardMobile {
    display: block;
  }

  .vipCardMobile :global(.linkBtn) {
    position: absolute;
    top: 110px;
    right: 5px;
  }
}

@media (max-width: 530px) {
  .vipCardMobile :global(.linkBtn) {
    top: 100px;
    font-size: 12px;
  }
}

@media (max-width: 490px) {
  .vipCardMobile :global(.linkBtn) {
    top: 90px;
  }
}

@media (max-width: 440px) {
  .vipCardMobile :global(.linkBtn) {
    top: 80px;
  }
}

@media (max-width: 410px) {
  .vipCardMobile :global(.linkBtn) {
    top: 70px;
  }
}

@media (max-width: 360px) {
  .vipCardMobile :global(.linkBtn) {
    top: 60px;
  }
}
