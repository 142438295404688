.contactItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--neutral-light-gray);
  border-radius: 6px;
  padding: 24px 26px 20px 24px;
  max-width: calc((100% / 3) - 10px);
  width: 100%;
  margin-bottom: 20px;
  margin-right: 0;
}

.contactItem:not(:last-child) {
  margin-right: 20px;
}

.contactTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.contactText {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-dark-blue-70);
  margin-bottom: 0;
}

.contactFooter {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .contactItem {
    max-width: none;
    width: 100%;
  }
}
