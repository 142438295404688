.questionPage :global(.rulesGroupItem h1) {
  margin-bottom: 20px;
}

.questionTopicBg {
  background-color: var(--additional-gray-blue-20);
}

.mobileView {
  display: none;
}

.gameDetails {
  background-color: var(--additional-gray-blue-20);
  margin-top: 10px;
  padding: 30px 0 30px;
  margin-bottom: -60px;
}

.provideFeedBackButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 10px;
  font-family: 'retro', serif;
  width: 100%;
  background-color: var(--button-primary-enabled);
  font-size: 22px;
  color: var(--whiteColor);
  text-decoration: none;
}

.provideFeedBackButton:hover {
  text-decoration: none;
  color: var(--whiteColor);
}

.provideFeedBackButton > div {
  padding-left: 10px;
}

@media (max-width: 600px) {
  .provideFeedBackButton {
    font-size: 16px;
  }
}
