.checkList {
  border-radius: 6px;
  background-color: var(--neutral-light-gray);
  padding: 16px 18px;
  color: var(--text-color);
}

.checkList :global(.custom-checkbox:not(:last-child)) {
  margin-bottom: 12px;
}
