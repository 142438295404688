.bottomDetailsContainer {
  column-count: 2;
  display: flex;
}

.bottomDetailsContainer > div {
  width: 100%;
}

.bottomDetailsContainer b {
  display: block;
  font-size: 18px;
  margin-bottom: 3px;
}

.bold {
  font-weight: bold;
  font-size: 17px;
}

.bottomDetailsContainer p {
  font-family: 'retro', serif;
}

@media (max-width: 991px) {
  .bottomDetailsContainer {
    display: inherit;
  }

  .bottomDetailsContainer > div {
    width: auto;
  }
}
