.container img {
  max-width: 310px;
  margin: 20px auto 0 auto;
  display: block;
}

.listWrapper {
  padding-top: 20px;
  margin: 0 auto;
  max-width: 550px;
}

.listWrapper li {
  margin: 6px 0;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.listWrapper li svg {
  margin-right: 8px;
  font-size: 1.2em;
}

.listWrapper span {
  display: block;
  font-size: 19px;
  font-weight: normal;
  padding-left: 38px;
}

@media (max-width: 500px) {
  .listWrapper span {
    padding-left: 0;
  }

  .listWrapper li {
    padding: 15px 5px;
  }
}
