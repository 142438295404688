:global .gameListContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
  justify-content: space-between;
  font-family: 'retro', serif;
  padding: 0 20px;
}
.gameButton {
  min-width: 130px;
  /*border-color: var(--theme-primary);*/
}

.gameButton + .gameButton {
  margin: 0 15px;
}

.gameButton:last-child {
  margin: 0;
}

.gameButton.selected {
  color: var(--whiteColor);
  background: var(--button-primary-enabled);
}

@media (max-width: 500px) {
  :global(.gameListContainer) {
    display: flex;
    flex-direction: column;
  }

  :global(.gameListContainer) .gameButton {
    margin: 10px 0;
  }
}
