.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  color: var(--text-color);
}

.contentWrapper p {
  padding-top: 15px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 10px;
}

.imagesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 320px;
}

.phoneImage,
.phoneBottomImage {
  max-width: 320px;
}

@media (max-width: 991px) {
  .container {
    flex-direction: column;
  }

  .contentWrapper {
    max-width: 100%;
    text-align: center;
  }

  .imagesWrapper {
    max-width: 100%;
  }

  .phoneImage {
    padding-top: 30px;
  }

  .buttonWrapper {
    padding-top: 20px;
    justify-content: center;
  }
}

@media (max-width: 500px) {
  .buttonWrapper {
    padding-top: 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px 0;
  }

  .buttonWrapper a:first-child {
    margin-right: 0;
  }

  .phoneImage {
    max-width: 250px;
  }
}
