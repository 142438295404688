:global .jumbotron {
  padding: 24px 20px 18px;
  background-color: var(--lavender-grey);
  margin-right: 0;
}

.theoryCard {
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

:global .topicListLogged .jumbotron {
  max-width: 350px;
  background-color: var(--whiteColor);
}

:global .topicListNotLogged .jumbotron {
  max-width: 350px;
  background-color: var(--lavender-grey);
}

.theoryCardTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  margin-bottom: 10px;
}

.theoryCardText {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  margin-bottom: 28px;
}

.linkBtn {
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  padding: 0;
  border: none;
  transition: all ease 0.3s;
}

.linkBtn i {
  font-size: 14px;
  color: currentColor;
}

.linkBtn:hover,
.linkBtn:focus {
  text-decoration: none;
}

@media (max-width: 991px) {
  :global .topicListLogged .jumbotron {
    background-color: var(--neutral-light-gray);
    margin-bottom: 20px;
  }
}
