.offersItemWrapper {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  gap: 0 40px;
  align-items: stretch;
}

.banner {
  margin: -20px auto 60px auto;
  display: block;
}

.listWrapper {
  padding: 0 15px;
}

.listWrapper li {
  margin: 10px 0;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  line-height: 25px;
}

.listWrapper li > div {
  display: flex;
}

.listWrapper li svg {
  margin-top: 8px;
  margin-right: 8px;
  font-size: 10px;
}

.langList {
  padding: 10px 15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.langList img {
  max-width: 45px;
}

img.abIcons {
  height: 41px;
}

@media (max-width: 991px) {
  .offersItemWrapper {
    flex-direction: column;
    gap: 40px 0;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .offersItemWrapper {
    flex-direction: column;
    gap: 40px 0;
    align-items: center;
  }

  .listWrapper li {
    font-size: 16px;
  }
}
