.loginBtnMobile,
.logoutBtnMobile {
  display: none;
  color: var(--neutral-dark-blue);
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.loginBtn,
.logoutBtn {
  display: block;
}

.langIconGroup {
  display: none;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 44px;
}

.navbar-nav .nav-link {
  color: var(--neutral-dark-blue);
  transition: all ease 0.3s;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: var(--button-default-pressed);
}

.loginBtnGroup {
  display: flex;
  align-items: center;
}

.loginBtnItem {
  margin-right: 35px;
}

.themSwiperContainerDesktop {
  margin-right: 20px;
  padding-top: 10px;
  transition: all ease-in 0.5s;
}

.themSwiperContainerMobile {
  display: none;
}

.isGamePath {
  opacity: 0;
}

@media (max-width: 1199px) {
  .loginBtnItem {
    margin-right: 10px;
  }
}

@media (max-width: 991px) {
  .loginBtnMobile,
  .logoutBtnMobile {
    display: block;
  }

  .themSwiperContainerDesktop {
    display: none;
  }

  .themSwiperContainerMobile {
    display: block;
  }

  .loginBtnItem.desktop {
    display: none;
  }

  .loginBtnGroup {
    align-items: flex-start;
  }

  .loginBtn,
  .logoutBtn {
    display: none;
  }

  .langIconGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0 0 -12px;
    width: 100%;
  }

  .langIconGroup a {
    width: 100%;
  }

  .langIconGroup button {
    background-color: unset;
    width: 100%;
  }

  .lanIconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .lanIconWrapper p {
    margin: 0 0 0 15px;
    font-weight: bold;
    font-size: 18px;
    color: var(--text-color);
  }

  .selectedLanguage {
    background-color: var(--additional-blue);
    border-radius: 5px;
  }

  .selectedLanguage p {
    color: #000;
  }
}

@media (max-width: 500px) {
  .langIconGroup button {
    background-color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    /*width: 43px;*/
  }

  .langIconGroup img {
    width: 43px;
  }
}
