.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  transition: all 0.3s ease;
}

.showLoader {
  display: flex;
}

.blockLoader {
  position: absolute;
}

.loader > i {
  font-size: 100px;
  width: 100px;
  height: 100px;
  color: var(--button-default-enabled);
  text-align: center;
  animation: rotate 2s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
