.instructorInfo {
  position: relative;
  background-color: var(--lavender-grey);
  border-radius: 10px;
  margin: 10% 0 10% auto;
  width: 100%;
  max-width: 500px;
  z-index: 5;
}

.instructorContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 40px 20px;
}

.instructorSchoolInfo {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.instructorContact {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 40px;
  border-top: 1px solid var(--additional-gray-blue);
}

.instructorContact:last-child,
.instructorContact:nth-child(3) {
  display: none;
}

.contactItem {
  flex-grow: 1;
  width: 50%;
}

.userName {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: var(--neutral-dark-blue);
}

.userInfo {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.instructorLabel {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 42px;
  color: var(--neutral-gray-blue-50);
}

.instructorValue {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.instructorText {
  margin-top: 65px;
}

@media (max-width: 991px) {
  .instructorInfo {
    margin-bottom: 0;
  }

  .instructorContent,
  .instructorContact {
    padding: 20px;
  }

  .userName {
    font-size: 18px;
    line-height: 24px;
  }

  .userInfo {
    font-size: 14px;
    line-height: 22px;
  }

  .instructorValue {
    font-size: 18px;
    line-height: 22px;
  }

  .instructorContact:last-child,
  .instructorContact:nth-child(3) {
    display: flex;
  }

  .instructorContact:nth-child(2) {
    display: none;
  }

  .instructorInfo::after {
    display: none;
  }
}

@media (max-width: 480px) {
  .instructorContent {
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .instructorContent :global(.schoolLogo) {
    max-width: unset;
    width: 100%;
  }

  .instructorSchoolInfo {
    margin-top: 20px;
    width: 100%;
  }
}
