.schoolInfoPage {
  padding-top: 24px;
}

.linkBtn {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  text-transform: uppercase;
  font-weight: 500;
}

.linkBtn i {
  display: block;
  transform: rotate(180deg);
  margin-right: 5px;
  font-size: 14px;
}
