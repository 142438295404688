.container h1 {
  text-align: center;
  padding-top: 25px;
  font-size: 50px;
}

.container img {
  padding-top: 50px;
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  display: block;
}

.imagesWrapper {
  display: flex;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
}

.imagesWrapper img {
  padding-top: 0;
  max-width: 300px;
  margin: 0 30px;
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logoWrapper img {
  max-width: 150px;
  margin: 0 5px;
}

.logoWrapper img:last-child {
  margin-top: 17px;
}

@media (max-width: 991px) {
  .imagesWrapper {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .imagesWrapper img:first-child {
    max-width: 240px;
  }

  .imagesWrapper img {
    max-width: 280px;
  }

  .logoWrapper img,
  .logoWrapper img:first-child {
    max-width: 150px;
  }
}

@media (max-width: 500px) {
  .logoWrapper img,
  .logoWrapper img:first-child {
    max-width: 140px;
  }

  .container h1 {
    font-size: 40px;
  }
}
