.container {
  min-height: 60vh;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorText {
  font-weight: bold;
  color: var(--functional-warning-bold);
  font-size: 20px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px 20px;
  padding-top: 20px;
}

@media (max-width: 680px) {
  .buttonWrapper {
    flex-direction: column;
  }

  .errorText {
    text-align: center;
  }
}
