.root:global(.modal-sm) {
  max-width: 355px;
  background-color: var(--lavender-grey);
  border-radius: 5px;
}

.modalHeader {
  padding: 0;
}

.modalHeader:global(.modal-header button.close:before) {
  color: var(--theme-primary);
}

.modalHeader:global(.modal-header button.close) {
  z-index: 20;
}
