.listContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listWrapper li {
  margin: 6px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.listWrapper li svg {
  margin-right: 8px;
  font-size: 1em;
}

.saleItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 30px;
  padding-top: 40px;
}

img.abIcons {
  height: 41px;
}

@media (max-width: 991px) {
  .saleItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px 30px;
    padding-top: 40px;
  }
}
