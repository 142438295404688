.questionBtnListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  cursor: pointer;
  color: var(--theme-primary);
  border-radius: 2px;
  margin-bottom: 12px;
}

.glossaryHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.glossaryHeader :global .glossary-title {
  margin-right: 10px;
}

.questionBtnListItem > i {
  font-size: 48px;
}

.glossaryFooter {
  position: absolute;
  bottom: 0;
  left: 44px;
  right: 44px;
  padding: 38px 44px 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
}

.footerLink {
  display: none;
}

.btnPrev,
.btnNext {
  position: absolute;
  top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: var(--additional-gray-blue);
  border-radius: 6px;
  transition: all ease 0.3s;
  min-width: auto;
}

.btnPrev:disabled,
.btnNext:disabled {
  opacity: 0;
  pointer-events: none;
}

.btnPrev > i,
.btnNext > i {
  font-size: 20px;
  color: var(--neutral-gray-blue);
}

.btnPrev {
  transform: rotate(180deg);
  left: 0;
}

.btnNext {
  right: 0;
}

.btnPrev:hover,
.btnNext:hover,
.btnPrev:focus,
.btnNext:focus,
.btnPrev:not(:disabled):not(.disabled):active,
.btnNext:not(:disabled):not(.disabled):active,
.btnPrev:not(:disabled):not(.disabled).active,
.btnNext:not(:disabled):not(.disabled).active,
.btnPrev:not(:disabled):not(.disabled):active:focus,
.btnNext:not(:disabled):not(.disabled):active:focus {
  background-color: var(--button-secondary-enable);
  box-shadow: none;
}

:global .glossary-subtitle {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-dark-blue);
}

:global .glossary-subtitle,
:global .glossary-text {
  margin-bottom: 12px;
  display: block;
  width: 100%;
}

:global .glossary-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  margin-bottom: 20px;
}

.glossaryImgList {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.theoryText {
  color: var(--text-color);
}

.imgContent,
.imgPlaceholderContent {
  display: inline-flex;
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-right: 24px;
}

.imgPlaceholderContent {
  width: 160px;
  height: 120px;
}

.glossaryScrollBox {
  max-height: calc(100% - 100px);
  height: 100%;
  overflow-y: auto;
}

.glossaryScrollBox::-webkit-scrollbar {
  border-radius: 6px;
  background-color: var(--additional-gray-blue);
}

.glossaryScrollBox::-webkit-scrollbar {
  width: 12px;
}

.glossaryScrollBox::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: var(--theme-primary);
}

.modal :global(.modal-content) {
  background-color: var(--whiteColor);
}

.modal,
.backdrop,
.topicLink {
  display: none !important;
}

.topicNumberWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topicNumberWrapper svg {
  width: 30px;
  height: 30px;
}

.topicNumber {
  color: var(--text-color);
}

@media (max-width: 991px) {
  .questionBtnListItem {
    width: 32px;
    height: 32px;
  }

  .questionBtnListItem > i {
    font-size: 32px;
  }

  .glossaryScrollBox {
    max-height: none;
    overflow-y: inherit;
  }

  .imgPlaceholderContent {
    width: 120px;
    height: 80px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .glossaryScrollBox.withModal {
    display: none;
  }

  .modal,
  .backdrop,
  .topicLink {
    display: block !important;
  }

  .topicNumber {
    margin-bottom: 0;
  }

  .glossaryFooter {
    position: relative;
    left: 0;
    right: 0;
    padding: 0;
    height: 48px;
  }

  .btnPrev,
  .btnNext {
    top: 0;
  }
}
