.socialLinkItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: var(--theme-primary);
  background-color: var(--button-secondary-enable);
  transition: all ease 0.4s;
}

.socialLinkItem:hover {
  text-decoration: none;
  color: var(--theme-primary);
  background-color: var(--button-icon-focused);
}

.socialLinkItemText {
  text-transform: uppercase;
  font-family: 'Hind', sans-serif;
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: var(--text-color);
  margin-top: 14px;
}

.socialLinkListMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 36px;
}

.socialLinkList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
}

.socialLinkList > li:not(:last-child) {
  margin-right: 40px;
}

.socialLinkList > li {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  .socialLinkListMobile {
    display: none;
  }
}
