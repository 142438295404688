.prevBtn,
.nextBtn {
  position: relative;
}

.prevBtn {
  margin-right: 30px;
}

.nextBtn {
  margin-left: 30px;
}

.prevBtn:before,
.nextBtn:before {
  position: absolute;
  content: '\e917';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  font-family: 'icomoon', sans-serif;
  color: var(--neutral-dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: var(--button-secondary-enable);
}

.prevBtn:before {
  transform: rotate(180deg);
}

.prevBtn a,
.nextBtn a {
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.prevBtn span,
.nextBtn span {
  display: none;
}

:global .page-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

:global .page-item:first-child,
:global .page-item:last-child {
  display: none;
}

.paginationItem a,
.paginationItem span {
  border: none;
  background-color: transparent;
  padding: 0 5px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 48px;
  color: var(--neutral-gray-blue);
  transition: all 0.3s ease;
}

:global .page-link:hover {
  color: var(--button-default-focused);
  background-color: transparent;
}

:global .page-item.active .page-link {
  color: var(--button-default-focused);
  border: none;
  background-color: transparent;
}

:global .page-link:focus {
  box-shadow: none;
}

.prevBtn:before,
.nextBtn:before {
  color: var(--text-color);
}

:global(.dark-theme) .prevBtn:before,
:global(.dark-theme) .nextBtn:before {
  background-color: var(--whiteColor);
  color: var(--text-color);
  border: 1px solid var(--not-changine-white);
}

@media (max-width: 991px) {
  .prevBtn {
    margin-right: 15px;
  }

  .nextBtn {
    margin-left: 15px;
  }

  .prevBtn:before,
  .nextBtn:before {
    font-size: 16px;
  }

  .prevBtn a,
  .nextBtn a {
    width: 32px;
    height: 32px;
  }

  .paginationItem a,
  .paginationItem span {
    font-size: 16px;
    line-height: 32px;
  }
}
