.resultPage {
  padding: 88px 0 140px;
}

.resultPageContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-right: 64px;
}

.resultButton {
  width: 100%;
}

.resultPageRateContainer {
  max-width: 320px;
  text-align: center;
  margin: -15px 0 20px 0;
}

.resultPageRateContainer p {
  margin: 0;
  border-radius: 6px 6px 0 0;
  background-color: var(--button-primary-disabled);
  padding: 8px 21px;
  font-size: 17px;
  font-weight: bold;
}

.resultPageRateContainer a {
  display: flex;
  color: var(--neutral-dark-blue);
  justify-content: center;
  align-items: center;
  background-color: var(--theme-accent);
  height: 35px;
  border-radius: 0 0 6px 6px;
}

.questionLinkLinkContainer {
  margin-top: 10px;
  max-width: 440px;
}

.questionLinkLinkContainer :global(.singleQuestionLink),
.questionLinkLinkContainer :global(.examListItem) {
  height: 35px;
  width: 35px;
  font-size: 12px;
}

.questionLinkLinkContainer :global(.examListItemIncorrect::after) {
  height: 12px;
  width: 6px;
  font-size: 14px;
  top: -7px;
  right: 0;
}

.questionLinkLinkContainer :global(.examListItemIncorrect span::before) {
  height: 12px;
  width: 6px;
  font-size: 16px;
  top: -8px;
  right: 1px;
}
.questionLinkLinkContainer :global(.examListItemCorrect) {
  border-color: var(--functional-success);
  border-width: 2px;
  background-color: var(--functional-success-10);
}

.questionLinkLinkContainer :global(.examListItemCorrect::after) {
  height: 14px;
  width: 8px;
  border-bottom: 2px solid var(--functional-success);
  border-right: 2px solid var(--functional-success);
  top: -5px;
  right: 0;
}

.questionLinkLinkContainer :global(.examListItemCorrect span::before) {
  height: 14px;
  width: 10px;
  border-bottom: 3px solid var(--whiteColor);
  border-right: 3px solid var(--whiteColor);
  top: -5px;
  right: 0;
}

.resultPage :global(.questionLinkList) {
  max-height: 100%;
}

.resultOverviewLink {
  margin: 0 auto;
  padding-top: 20px;
  font-weight: 500;
  display: block;
  text-align: center;
}

.resultPageContentItem :global(.text-center) {
  color: var(--text-color);
}

@media (max-width: 1199px) {
  .resultPage {
    padding: 24px 0 0;
  }

  .resultPageContent {
    flex-flow: row wrap;
    padding-right: 0;
  }

  .resultPageContentItem {
    width: 100%;
  }

  .examButtonList {
    flex-wrap: wrap;
    max-width: 320px;
  }

  .examButtonList button {
    margin-top: 10px;
    width: 100%;
    margin-right: 0 !important;
  }
}

@media (max-width: 600px) {
  .resultPageRateContainer {
    max-width: 440px;
  }

  .examButtonList {
    max-width: 440px;
  }

  .examButtonList button {
    max-width: 440px !important;
  }

  .questionLinkLinkContainer {
    justify-content: center;
    max-height: unset;
  }
}
