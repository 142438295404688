.topContainer h1 {
  font-size: 50px;
  margin-bottom: 30px;
}

.aboutPage {
  padding: 60px 0 30px;
}

.topContainer {
  padding-bottom: 20px;
}

.aboutBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutBtn {
  margin: 12px 0 28px;
  padding: 6px 65px;
}

.listTitle {
  margin: 0;
  color: var(--text-color);
}

.whiteTitle {
  margin: 0;
  color: var(--not-changine-white);
}

:global(body.dark-theme) .listTitle {
  color: var(--not-changine-white);
}

:global(body.lady-theme) .listTitle {
  color: var(--text-color);
}

.gameContainer {
  padding: 40px 0 20px 0;
  background-color: var(--not-changine-black);
}

.gameContainer {
  color: var(--whiteColor);
}

.gameText {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 18px;
  color: var(--not-changine-white);
}

body .aboutPageGameSubtitle {
  color: var(--not-changine-white);
  margin: 40px 0 28px;
}

.listContainer {
  padding: 40px 0 20px 0;
  background-color: var(--neutral-light-gray);
}

.listText {
  font-size: 15px;
  /*line-height: 24px;*/
  color: var(--neutral-gray-blue);
  margin-bottom: 18px;
}

.aboutPageSubtitle {
  color: var(--neutral-dark-blue);
  margin: 20px 0 28px;
}

.aboutPageTextList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.imageContainer {
  padding: 40px 0;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper img {
  width: 100%;
  max-width: 600px;
}

.aboutPageItem {
  max-width: 33.3%;
  width: 100%;
}

.aboutPageItem img {
  max-width: 100%;
}

.aboutPageItem:not(:last-child) {
  margin-right: 26px;
}

.aboutPageText {
  font-size: 17px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.videoTitle {
  text-align: center;
  text-transform: initial;
  margin: 20px 0;
}

.videoContainer {
  padding: 40px 0;
}

.videoWrapper {
  position: relative;
  padding-bottom: 37%; /* 16:9 */
  height: 0;
  max-width: 650px;
  margin: 0 auto 40px auto;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .aboutPage {
    padding: 0;
  }

  .aboutPageTextList {
    flex-flow: row wrap;
  }

  .aboutPageItem {
    max-width: none;
  }

  .aboutPageItem:not(:last-child) {
    margin-right: 0;
  }

  .aboutPageItem:not(:first-child) {
    margin-top: 20px;
  }

  .aboutBtn {
    max-width: 320px;
    width: 100%;
    margin: 12px auto 28px;
    display: block;
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 60%; /* 16:9 */
    height: 0;
    max-width: 650px;
    margin: 0 auto 40px auto;
  }
}

@media (max-width: 500px) {
  .topContainer h1 {
    font-size: 40px;
    padding-top: 25px;
  }
}
