.actionBtn {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--not-changine-white);
  width: 100%;
  border-radius: 0 0 4px 4px;
}

.text {
  color: var(--not-changine-black);
}
