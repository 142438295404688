.schoolTable {
  margin-top: 36px;
}

.tableHeader,
.tableBodyRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
}

.tableHeaderItem {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-gray-blue);
  width: 100%;
}

.tableBodyRow {
  background-color: var(--neutral-light-gray);
  border-radius: 6px;
  margin-bottom: 16px;
}

.tableBodyRowItem {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  text-align: left;
  width: 100%;
}

.tableBodyRowItem:not(:last-child) {
  margin-right: 29px;
}

.tableBodyRowItem:first-child,
.tableBodyRowItem:last-child,
.tableHeaderItem:first-child,
.tableHeaderItem:last-child {
  width: 32px;
  height: 32px;
}

.tableBodyRowItem:first-child,
.tableHeaderItem:first-child {
  display: none;
}

.tableBodyRowItem:nth-child(2),
.tableHeaderItem:nth-child(2) {
  max-width: 25%;
}

.tableBodyRowItem:nth-child(3),
.tableHeaderItem:nth-child(3) {
  max-width: 30%;
}

.tableBodyRowItem:nth-child(4),
.tableHeaderItem:nth-child(4) {
  max-width: 15%;
}

.tableBodyRowItem:nth-child(5),
.tableHeaderItem:nth-child(5) {
  max-width: 10%;
}

.tableFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 36px;
}

.linkBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.linkBtn i {
  font-size: 14px;
}

@media (max-width: 991px) {
  .tableBodyRowItem:nth-child(4),
  .tableHeader,
  .tableBodyRowItem:nth-child(3) {
    display: none;
  }

  .tableBodyRowItem:nth-child(2) {
    order: 1;
    max-width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tableBodyRowItem:first-child {
    order: 2;
  }

  .tableBodyRowItem:nth-child(5) {
    order: 3;
    max-width: 20%;
  }

  .tableBodyRowItem:last-child {
    order: 4;
  }

  .tableBodyRowItem {
    font-size: 14px;
    line-height: 21px;
  }

  .tableBodyRowItem:not(:last-child) {
    margin-right: 0;
  }

  .tableBodyRow {
    padding: 16px;
  }

  .tableBodyRowItem:first-child,
  .tableBodyRowItem:last-child {
    width: 24px;
    height: 24px;
  }

  .tableFooter {
    justify-content: center;
  }
}
