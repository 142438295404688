.scoreContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin-bottom: 14px;
}
.scoreValue {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 60px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-bottom: 14px;
}

.loaderWrapper {
  height: 150px;
}
