.ebikeContent {
  padding: 64px 0 95px;
  background-color: var(--whiteColor);
}

video.responsiveVideo {
  max-width: 100%;
  height: auto
}

.ebikeContentText {
  padding: 60px 0 100px;
  background-color: var(--neutral-light-gray);
}

.ebikeContentText :global(.nav) {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

.ebikeContentText :global(.nav-item) {
  padding: 17px 30px 16px 16px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-bottom: 12px;
  background-color: var(--whiteColor);
}

.ebikeContentText :global(.nav-link) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  padding: 0;
  color: var(--neutral-dark-blue);
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  transition: all ease-in 0.3s;
}

.ebikeContentText :global(.nav-link):hover,
.ebikeContentText :global(.nav-link.active) {
  color: var(--button-default-focused);
}

.ebikeContentText :global(.nav-link .icon-arrow) {
  font-size: 14px;
}

.ebikeTextList :global(.h8),
.ebikeTextList h4,
.ebikeContent :global(.p3) {
  color: var(--neutral-dark-blue);
}

.ebikeTextList {
  color: var(--neutral-gray-blue);
}

.ebikeTextTitle {
  margin-bottom: 24px;
}

.ebikeImgBox {
  margin-top: 12px;
}

.ebikeImgBox img {
  margin-bottom: 24px;
  width: 100%;
  height: auto;
}
