.loadingElement {
    height: 100%;
}

.container {
    height: 400px;
}

.map {
    height: 100%;
}
