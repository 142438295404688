.root {
  padding: 60px 0 30px;
  color: var(--text-color);
}

.mainImage {
  margin: 30px 0;
}

.video {
  margin: 30px 0;
}

.root ul {
  margin-bottom: 25px;
}

@media (max-width: 780px) {
  .mainImage {
    height: 185px;
    width: 300px;
  }

  .video {
    height: 450px;
    width: 550px;
  }

  .mainTitle {
    font-size: 38px;
    line-height: 44px;
  }
}

@media (max-width: 680px) {
  .video {
    height: 185px;
    width: 300px;
  }
}
