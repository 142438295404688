.confirmedPage {
  margin-bottom: -57px;
}

.confirmedPageContent {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
}

.confirmedPageText,
.imgContainer {
  max-width: calc(50% - 10px);
  width: 100%;
}

.imgContainer img {
  height: 100%;
  width: auto;
}

.confirmedPageText h1 {
  margin-bottom: 24px;
}

.pageTextSmall {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
  margin-bottom: 16px;
}

.pageTextSubtitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--neutral-gray-blue);
  max-width: 340px;
  margin-bottom: 48px;
}

.linkBtn {
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: var(--button-default-focused);
  padding: 0;
  border: none;
  text-transform: uppercase;
  transition: all ease 0.3s;
}

.linkBtn i {
  display: inline-block;
  margin-left: 5px;
  transform: rotate(180deg);
  font-size: 14px;
  color: currentColor;
}

.linkBtn:hover,
.linkBtn:focus {
  text-decoration: none;
}

@media (max-width: 991px) {
  .confirmedPage {
    padding: 24px 0 60px;
    margin-bottom: 0;
  }

  .confirmedPageContent {
    flex-flow: row-reverse wrap;
  }

  .confirmedPageText,
  .imgContainer {
    max-width: none;
  }

  .imgContainer {
    display: none;
  }

  .confirmedPageText {
    text-align: center;
  }

  .pageTextSmall {
    font-family: 'Hind', sans-serif;
    font-size: 15px;
    line-height: 21px;
    color: var(--neutral-gray-blue);
    margin-bottom: 16px;
  }

  .pageTextSubtitle {
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto 28px;
  }
}

@media (max-width: 767px) {
  .confirmedPageText {
    text-align: left;
  }

  .pageTextSubtitle {
    max-width: none;
  }

  .pageTextSubtitle {
    margin: 0 0 28px;
  }
}
