.container {
  position: relative;
}

.mapContainer {
  height: 640px;
}

@media (max-width: 991px) {
  .mapContainer {
    height: 350px;
  }
}
