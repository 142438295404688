.bottomDescription {
  column-count: 3;
  column-width: 390px;
  column-gap: 20px;
}

@media (max-width: 991px) {
  .bottomDescription {
    column-width: 320px;
  }
}
