.title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 25px;
  line-height: 30px;
  /*text-transform: uppercase;*/
  margin-bottom: 40px;
  color: var(--neutral-dark-blue);
  text-align: center;
}

.container :global(.dropdown-menu) {
  text-align: center;
  width: 100%;
}

.container :global(.btn-secondary) {
  min-width: 100%;
  position: relative;
}

.container :global(.btn-secondary:after) {
  position: absolute;
  right: 5px;
  top: 17px;
}

.container :global(.dropdown-item) {
  padding: 0;
}

.selectDifficultyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectDifficultyWrapper svg {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}
