.offersPageText {
  padding-top: 60px;
}

.keyBenefitsContainer {
  padding: 90px 0;
}

.offersContainer,
.imageBreakContainer,
.shopLinkContainer {
  padding: 90px 0;
  background-color: var(--lavender-grey);
}

.vipCodeContainer {
  padding-top: 90px;
}
