.text {
  font-size: 40px;
  line-height: 36px;
  color: var(--neutral-dark-blue);
  margin-bottom: 20px;
}

.center {
  text-align: center;
}

@media (max-width: 500px) {
  .text {
    font-size: 30px;
  }
}
