.paymentImgBox {
  background-color: var(--whiteColor);
  border-radius: 5px;
  width: 53px;
  height: 34px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 23px;
}

.paymentImgBox img {
  max-height: 34px;
  max-width: 53px;
  width: auto;
}
