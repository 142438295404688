.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 112px;
  width: 112px;
  margin-right: 20px;
  background-color: var(--theme-primary-80);
  border-radius: 4px;
}

.avatarImg {
  max-width: 100%;
  height: auto;
}

.avatarText {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 60px;
  color: var(--additional-light-blue);
}

@media (max-width: 991px) {
  .avatar {
    width: 80px;
    height: 80px;
  }

  .avatarText {
    font-size: 40px;
  }
}
