.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /*padding:0 10%;*/
}

.container img {
  max-width: 310px;
}

.container a {
  color: var(--text-color);
}

.container h6 {
  text-align: right;
  padding-top: 5px;
  color: var(--theme-primary-80);
}

.container h5 {
  text-align: right;
}

@media (max-width: 991px) {
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    /*padding:0 10%;*/
  }

  .container h6,
  .container h5 {
    text-align: center;
  }

  .container img {
    padding-top: 20px;
    max-width: 340px;
    width: 100%;
  }
}
