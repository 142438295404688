.rulesGroupContent {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .rulesGroupContent {
    flex-wrap: wrap;
  }
}
