.ads {
  background-color: var(--gray-6);
}

.ads a {
  display: flex;
  justify-content: center;
}

.ads img {
  max-width: 100%;
  height: auto;
}
