.faqPage {
  padding: 80px 0 60px;
}

.faqList {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
}

.faqListContainer {
  max-width: 30%;
  width: 100%;
}

.faqPage :global .pageTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
}

.faqLink {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
  text-transform: none;
}
.faqLink:hover {
  text-decoration: none;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .faqPage {
    padding: 0;
  }

  .faqPage :global .pageTitle {
    font-size: 28px;
    line-height: 42px;
  }

  .faqList {
    margin-top: 12px;
  }

  .faqListContainer {
    max-width: none;
  }
}
