.container {
  width: 100%;
  transition: all ease-in 1s;
  opacity: 1;
  padding: 0 4px;
}

.container path {
  transition: all ease-in 0.5s;
}

.hidden {
  opacity: 0;
}

@media (max-width: 560px) {
  .container span {
    font-size: 18px;
  }
}
