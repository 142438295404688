.rulesGroupItem {
  max-width: calc(50% - 10px);
  width: 100%;
  z-index: 5;
  /*margin-bottom: 100px;*/
}

:global .rulesGroupContent h1 {
  margin-top: 92px;
}

@media (max-width: 1199px) {
  .rulesGroupItem {
    max-width: 720px;
    width: auto;
    margin: 0 auto;
    text-align: center;
  }

  .rulesGroupItem > .imgContainer,
  .rulesGroupItem:first-child {
    display: none;
  }
}

@media (max-width: 991px) {
  .rulesGroupItem {
    max-width: none;
    width: auto;
    margin: 0 auto;
  }

  .rulesGroupItem :global h1 {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .rulesGroupItem :global h1 {
    text-align: left;
  }
}
