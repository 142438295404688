.placeholderContainer {
  padding-top: 75px;
  padding-bottom: 75px;
}

.invert.placeholderContainer {
  background-color: var(--additional-gray-blue);
}

.box {
  width: 100%;
  height: 220px;
  margin-bottom: 15px;
}

.box.vertical {
  height: 460px;
}

.invert.box {
  background-color: var(--whiteColor);
}

@media (max-width: 767px) {
  .box.mbRaw {
    height: 60px;
  }

  .box.vertical {
    height: 220px;
  }
}
