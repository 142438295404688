.singleCardWrapper {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.singleCardWrapper p {
  font-family: 'retro', serif;
  color: var(--text-color);
}

.answerTextContainer {
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.answerText {
  display: flex;
  justify-content: center;
  align-items: center;
  /*max-width: 310px;*/
  /*width: 100%;*/
  height: 100%;
  color: var(--whiteColor);
  font-weight: normal;
  font-size: 22px;
  margin: 0;
  border-radius: 0;
  padding: 0 20px;
}

.answerTextWrong {
  background-color: var(--functional-warning-bold);
}

.answerTextCorrect {
  background-color: var(--functional-success-bold);
}

.answerTextNotAnswer {
  background-color: var(--not-answer);
}

.singleCardWrapper :global(.card) {
  max-width: 650px;
  background-color: var(--whiteColor);
  border: 4px solid #000000;
}

.singleCardWrapper :global(.card .card-img-top) {
  padding: 20px;
  border-radius: 30px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  margin: 0 auto;
}

.wrongButton {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  font-family: 'retro', serif;
  padding: 0 0 10px 0;
  font-size: 38px;
  line-height: 0;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--wrong-game-button);
}

.correctButton {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--functional-success);
}

.correctButton img {
  width: 35px;
  height: 35px;
}

.titleText {
  text-align: left;
  font-size: 20px;
  font-weight: normal;
}

.btnPrev,
.btnNext,
.btnExit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  border: none;
  cursor: pointer;
  min-width: 0;
  flex-shrink: 0;
}

.btnPrev {
  margin-right: 10px;
}

.btnPrev svg,
.btnNext svg {
  width: 50px;
  height: 50px;
}

.btnPrev svg path,
.btnNext svg path {
  fill: var(--blackColor);
}

.btnNext svg {
  transform: rotate(180deg);
}

.isButtonDisabled {
  cursor: unset;
}

.wrongCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border: 2px solid var(--wrong-game-button);
  /*border-radius: 100%;*/
}

.correctCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border: 2px solid var(--functional-success);
  /*border-radius: 100%;*/
}

@media (max-width: 500px) {
  .answerText {
    font-size: 17px;
  }
}
