.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 5px;
  background-color: var(--whiteColor);
  color: var(--text-color);
  max-width: 460px;
  box-shadow: 0 4px 10px 3px rgba(0, 128, 201, 0.2);
}

.categoryText {
  text-align: center;
  margin: 30px 0;
  font-weight: bold;
  font-size: 28px;
}

.content {
  padding: 15px 15px 0 15px;
  position: relative;
}

.topWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.description {
  margin-top: 5px;
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 5px;
  height: 48px;
  width: 100%;
}

.iconWrapper img {
  max-width: 100px;
  height: 48px;
}

.topWrapper p {
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 25px;
  margin: 0;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  position: relative;
}

.buttonWrapper :global(.btn) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}

@media (max-width: 600px) {
  .categoryText {
    font-size: 24px;
  }
}

@media (max-width: 350px) {
  .categoryText {
    font-size: 22px;
  }
}
