.gamePage {
  background-color: var(--not-changine-black);
  overflow: hidden;
}

.trafficLightContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 380px;
}

.questionLinkBottom {
  max-width: 435px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
  /*padding-bottom: 30px;*/
  margin-top: 40px;
}

.questionLinkBottom :global(.btn) {
  font-family: 'retro', serif;
}

.questionLinkBottom svg {
  margin: 0 10px;
}

.questionLinkContainer {
  display: flex;
  justify-content: center;
}

@media (max-width: 550px) {
  .questionLinkBottom :global(.btnLabel) {
    border: 1px solid;
  }

  .questionLinkBottom {
    margin: 10px 0;
  }

  .questionLinkBottom a {
    margin: 0 5px;
  }
}
