.resultPage {
  padding: 88px 0 140px;
  position: relative;
  z-index: 1;
  font-family: 'retro', serif;
}

.resultPage h1,
.resultPage span,
.resultPage div,
.resultPage button {
  font-family: 'retro', serif;
}

.examButtonList {
  flex-wrap: wrap;
  max-width: 320px;
}

.examButtonList button {
  margin-top: 10px;
  width: 100%;
}

.examButtonList button:global(.btn:first-child) {
  margin-right: 0;
}

.resultPageContent {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-right: 0;
}

.resultPageRateContainer {
  max-width: 320px;
  text-align: center;
  margin: -15px 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resultPageRateContainer p {
  margin: 0;
  border-radius: 6px 6px 0 0;
  background-color: var(--button-primary-disabled);
  padding: 8px 21px;
  font-size: 17px;
  font-weight: bold;
}

.resultPageRateContainer a {
  display: flex;
  color: var(--neutral-dark-blue);
  justify-content: center;
  align-items: center;
  background-color: var(--theme-accent);
  height: 35px;
  border-radius: 0 0 6px 6px;
}

.examButtonList {
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .resultPage {
    padding: 24px 0 0;
  }

  .resultPageContentItem {
    width: 100%;
  }

  .examButtonList button {
    margin-top: 10px;
    width: 100%;
    margin-right: 0 !important;
  }
}

@media (max-width: 600px) {
  .resultPageRateContainer {
    max-width: 440px;
  }

  .examButtonList {
    max-width: 440px;
  }

  .examButtonList button {
    max-width: 440px !important;
  }
}
