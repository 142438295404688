.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.bounceScore {
  color: var(--functional-success-bold);
  font-weight: 500;
  font-size: 40px;
  position: absolute;
  z-index: 999;
  opacity: 0;
}

.defaultHeart {
  height: 25px;
  width: 25px;
}

.defaultHeart polygon {
  fill: var(--wrong-game-button);
}

.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-gray);
  background-color: var(--whiteColor);
  max-width: 650px;
  width: 100%;
  border-radius: 5px;
}

.wrapper span {
  font-family: 'retro', serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--neutral-gray-blue);
  text-align: center;
}

.topLayer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-gray);
}

.bottomLayer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerCountBox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.headerScoreContainer {
  display: flex;
  justify-content: center;
}

.headerScoreBox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
}

.doubleCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'retro', serif;
  font-weight: 500;
  height: 50px;
  font-size: 13px;
  width: 100%;
  line-height: 20px;
  color: var(--neutral-gray-blue);
}

.lifeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-left: 1px solid var(--neutral-gray);
  height: 50px;
  width: 100%;
}

.lifeContainer svg {
  margin: 2px;
}

.doubleCounter div,
.lifeContainer div {
  margin: 0;
  padding: 0;
}

.scoreWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: 'retro', serif;
  font-weight: 500;
  height: 43px;
  font-size: 13px;
  width: 100%;
  line-height: 20px;
  color: var(--neutral-gray-blue);
  transition: all ease-in 0.5s;
  white-space: nowrap;
}

.extraPointContainer {
  width: 100%;
  height: 43px;
  line-height: 20px;
  border-left: 1px solid var(--neutral-gray);
}

.containerHide {
  opacity: 0;
  padding: 0;
  height: 0;
  width: 0;
}

.livesHeartContainer {
  position: relative;
}

.controllerItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controllerItem span {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}

.loseHeart {
  position: absolute;
  animation-duration: 1.5s;
  opacity: 0;
  right: -25px;
}

.loseHeartActive {
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}

.questionBtnListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--theme-primary);
  border: none;
  padding: 0;
}

.questionBtnListItem > i {
  font-size: 36px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerCountBox button {
  background-color: var(--whiteColor);
  border: 1px solid var(--blackColor);
  color: var(--text-color);
}

@media (max-width: 500px) {
  .lifeContainer svg {
    width: 18px;
  }
}

@keyframes fadeOut {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: -50px;
    opacity: 0;
  }
}
