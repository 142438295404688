:global(.dark .navbar-toggler-icon) {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.headerTop {
  position: relative;
  background-color: var(--light-white);
  z-index: 10;
  transition: all 0.5s ease;
}

.headerTop :global(.navbar-nav) {
  align-items: center;
}

.headerTop :global(.navbar-nav) {
  align-items: center;
}

.headerTopContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.headerTopLinkList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.headerTopLinkList li:not(:last-child) {
  margin-right: 8px;
}

.headerTopLinkItem {
  color: var(--theme-primary);
  transition: all ease-in-out 0.4s;
}

.headerTopLinkItem:hover {
  text-decoration: none;
  color: var(--theme-primary);
}

.langBtnGroup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.langBtnGroup .button:not(:first-child) {
  margin-left: 28px;
}

.langBtnGroup .button {
  font-family: 'Hind', sans-serif;
  font-size: 13px;
  line-height: 21px;
  color: var(--neutral-dark-blue);
  transition: all ease-in-out 0.4s;
  border: none;
  background: transparent;
}

.langBtnGroup .button:hover {
  color: var(--theme-primary);
  cursor: pointer;
}

.mobileButtons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.loginBtnMobile {
  margin: 0 5px;
  height: 30px;
  min-width: unset;
  padding: 15px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demoVersionMobile {
  position: relative;
  display: flex;
  margin: 0 23px;
  justify-content: flex-end;
  background: url('../../../src/img/demo-label.svg') no-repeat 50% 50%;
  background-size: contain;
  height: 60px;
  width: 60px;
  align-items: center;
  padding: 0;
}

.demoVersionMobile:before {
  position: absolute;
  content: '';
  left: 0;
  top: -16px;
  width: 100%;
  height: 30px;
  background-color: var(--theme-accent);
  z-index: 1;
}

.demoText {
  font-family: 'Hind', sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--blackColor);
  max-width: 100%;
  text-align: center;
}

:global .navbar {
  padding: 0;
  z-index: 10;
}

.accountBtn i {
  color: var(--theme-primary);
  font-size: 24px;
}

.accountBtn {
  display: none;
}

@media (max-width: 991px) {
  .headerTop {
    display: none;
  }

  .accountBtn {
    display: flex;
  }

  .demoVersionMobile {
    margin: 0 10px;
  }
}

@media (min-width: 992px) {
  .demoVersionMobile {
    display: none;
  }

  .loginBtnMobile {
    display: none;
  }
}

@media (max-width: 375px) {
  .demoVersionMobile {
    margin: 0 5px;
  }

  :global(.navbar-brand) {
    margin-right: 0;
  }

  .loginBtnMobile {
    padding: 10px 15px;
  }
}
