.scoreContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.scoreTitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  /*text-transform: uppercase;*/
  color: var(--neutral-dark-blue);
}

.scoreContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  margin-bottom: 14px;
  padding-top: 15px;
  color: var(--text-color);
}
.scoreValue {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 60px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-bottom: 14px;
}

.scoreText {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: var(--functional-success);
  margin-left: 10px;
}

.loaderWrapper {
  height: 150px;
}
