@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?h02t3m');
  src: url('../fonts/icomoon.eot?h02t3m#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?h02t3m') format('truetype'), url('../fonts/icomoon.woff?h02t3m') format('woff'),
    url('../fonts/icomoon.svg?h02t3m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-speaker:before {
  content: '\e91c';
}
.icon-zoom:before {
  content: '\e908';
}
.icon-star-line:before {
  content: '\e900';
}
.icon-profile:before {
  content: '\e931';
}
.icon-arrow-fill:before {
  content: '\e918';
}
.icon-arrow:before {
  content: '\e917';
}
.icon-close:before {
  content: '\e909';
}
.icon-grid:before {
  content: '\e90a';
}
.icon-list:before {
  content: '\e91b';
}
.icon-star-fill:before {
  content: '\e907';
  color: var(--button-primary-pressed);
}
.icon-driving-school:before {
  content: '\e901';
}
.icon-check:before {
  content: '\e902';
}
.icon-multilang:before {
  content: '\e904';
}
.icon-theory:before {
  content: '\e905';
}
.icon-support:before {
  content: '\e90b';
}
.icon-email:before {
  content: '\e90c';
}
.icon-share:before {
  content: '\e90d';
}
.icon-whatsapp:before {
  content: '\e90e';
}
.icon-facebook:before {
  content: '\e90f';
}
.icon-instagram:before {
  content: '\e910';
}
.icon-twitter:before {
  content: '\e911';
}
.icon-linkedine:before {
  content: '\e912';
}
.icon-e-bike:before {
  content: '\e913';
}
.icon-tractor:before {
  content: '\e914';
}
.icon-motorcycle:before {
  content: '\e915';
}
.icon-car:before {
  content: '\e916';
}
