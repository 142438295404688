.questionBtnList {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}

.btnPrev {
  transform: rotate(180deg);
}

.questionBtnListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--theme-primary);
  border: none;
  padding: 0;
}

.questionBtnListItem > i {
  font-size: 36px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionBtnListItem:not(:last-child) {
  margin-right: 10px;
}

.lngBtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 5px;
  padding: 3px;
}

@media (max-width: 991px) {
  .questionBtnListItem {
    width: 32px;
    height: 32px;
  }

  .questionBtnListItem > i {
    font-size: 26px;
  }
}
